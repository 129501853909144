<template>
  <div class="row m-0">
    <div class="col-12 p-0 card_item">
      <div class="row mx-0 h-100 justify-content-center">
        <div class="col-12 pt-3 text-center card_heading d-md-none">
          Your Donation Cart
        </div>
        <Tabs v-model="paymentTypeValue" @login="$emit('login')" />
        <div class="col-12 p-0">
          <div class="row m-0 justify-content-center">
            <transition name="fade" mode="out-in">
              <div class="col-12 p-0 border_top" v-if="updatedCart && updatedCart.length > 0 && isEdit">
                <div class="row mx-0">
                  <div class="col-12 p-0" v-for="(item, key) in updatedCart" :key="key" >
                    <CartItemEdit :item="item" :itemKey="key" v-model="updatedCart[key]" @deleteItem="deleteItem(key)" />
                  </div>
                </div>
              </div>
              <div class="col-12 p-0 border_top" v-else-if="cart.length > 0 && !isEdit">
                <CartItem v-for="(item, key) in cart" :key="key" :item="item" />
              </div>
              <div class="col-12 p-5 border_top text-center font17 green-text-dark medium" v-else>
                <div class="row">
                  <div class="col-12 mb-4">
                    Your cart is empty. Please add an appeal/project.
                  </div>
                  <div class="col-12 ">
                    <Button color="green_light" size="smallmobile" btnText="Add Projects" icon="arrow" @buttonClicked="addProject">
                      <IconPlus />
                    </Button>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
        <div class="col-12 p-0 align-self-end ">
          <div class="row m-0 background-green py-3">
            <div class="col-6 col-md-auto px-2 px-md-4 order-md-first" v-if="cart.length > 0">
              <Button color="gold" size="smallmobile" width="100" btnText="Update Cart" icon="arrow" @buttonClicked="saveCart" v-if="isEdit">
                <ios-checkmark-icon class="inline-icon"/>
              </Button>
              <Button color="green_light" size="smallmobile" width="100" btnText="Edit Cart" icon="arrow" @buttonClicked="edit" v-else>
                <IconEdit size="size16" color="white" />
              </Button>
            </div>
            <div class="col-md align-self-end text-center relative order-first order-md-2 my-3 mb-md-0">
              <Button color="gold" :btnText="total" class="position-absolute start-50 translate-middle abs font20 text-nowrap lh-1" />
              <div class="total">CART TOTAL</div>
            </div>
            <div class="col-6 col-md-auto px-2 px-md-4 order-md-last" v-if="cart.length > 0">
              <Button color="green_light" size="smallmobile" width="100" btnText="Add Projects" icon="arrow" @buttonClicked="addProject" :disabled="isEdit">
                <IconPlus />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CartItem: defineAsyncComponent(() => import('./components/CartItem.vue')),
    CartItemEdit: defineAsyncComponent(() => import('./components/CartItemEdit.vue')),
    Tabs: defineAsyncComponent(() => import('./views/tabs.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    IconPlus: defineAsyncComponent(() => import('@/components/icons/IconPlus.vue')),
    'ios-checkmark-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-checkmark.vue'))
  },
  props: ['editStatus', 'paymentType'],
  emits: ['update:paymentType', 'login'],
  name: 'Cart Card',
  data () {
    return {
      isEdit: false,
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      updatedCart: null
    }
  },
  async mounted () {
    this.updatedCart = this.cart
  },
  watch: {
    editStatus () {
      this.isEdit = this.editStatus
    }
  },
  computed: {
    ...mapGetters([
      'cart', 'user'
    ]),
    paymentTypeValue: {
      get () {
        return this.paymentType
      },
      set (val) {
        this.$emit('update:paymentType', val)
      }
    },
    total () {
      if (this.cart.length > 0) {
        let ret = 0
        this.cart.forEach(item => {
          ret += Number(item.amount)
        })
        return this.cart[0].currency + ' ' + parseFloat(Math.round(ret * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return this.defaultCurrency + ' ' + parseFloat(Math.round(0 * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
    }
  },
  methods: {
    ...mapActions([
      'updateCart',
      'deleteCartItem'
    ]),
    edit () {
      this.isEdit = true
    },
    addProject () {
      window.scrollTo(0, 0)
      this.$router.push('/donate')
    },
    async deleteItem (val) {
      this.isEdit = false
      await this.updatedCart.splice(val, 1)
      this.isEdit = true
    },
    async saveCart () {
      await this.updateCart(this.updatedCart)
      this.$emit('saveEdit')
      this.isEdit = false
    }
  }
}
</script>

<style scoped>
.card_item {
  color: var(--normal-color);
  background-color: #fff;
  border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.card_heading {
  color: #3A3A3A;
  font-family: "quicksand_bold", Sans-serif;
  font-size: 13px;
  font-weight: 600;
}
.background-green {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.total {
  color: var(--green-color-light);
  font-size: 12px;
  font-family: "quicksand_bold", Sans-serif;
  letter-spacing: 0.35rem;
}
.popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
  color: #000
}
.border_top {
  border-top: 1px solid #E0E0E0;
}
.abs {
  bottom: 40%;
}
@media (min-width: 992px) {
  .abs {
    bottom: 70%;
  }
}
</style>
